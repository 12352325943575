import React, { useState, useEffect } from "react";
import "./PaymentSuccess.css";
import { notifySuccess } from "../../utils/common.service";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "../../service/service";
import { notifyError } from "../../utils/common.service";
import { LoadingButton } from "@mui/lab";
import Contact from "../contact";
import Navigation from "../navigation";
const PaymentSuccess = () => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const navigate = useNavigate();

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const registerUser = await userService.paymentStatus({
        sessionId: sessionId || "",
      });

      if (registerUser && registerUser.status === 200) {
        notifySuccess("Your payment status has been successfully");
        setLoading(false);
        setTimeout(() => {
          navigate("/userDashboard");
        }, 3000);
      } else if (registerUser && registerUser.status === 409) {
        notifySuccess("Payment already done successfully!!");
        setLoading(false);
        setTimeout(() => {
          navigate("/userDashboard");
        }, 3000);
      } else {
        setLoading(false);
        notifyError("Your payment status has been failed");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const onbuttonClick = () => {
    navigate("/userDashboard");
  };
  return (
    <>
      <Navigation />
      <div className="loader-container">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="after-loader-container">
            <div>
              <h1>Your Payment is Received Successfully! </h1>
              <h2>You are redirected to homepage in while...</h2>
            </div>
            <div>
              <LoadingButton
                className="common-button"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                onClick={onbuttonClick}
              >
                {loading ? "" : "Go to UserDashboard"}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
      <Contact />
    </>
  );
};

export default PaymentSuccess;
