import { API_CONSTANT } from "../utils/constant.jsx";
import httpCommon from "../core/http.common.jsx";

const userService = {
  register(body) {
    return httpCommon.post(API_CONSTANT.SIGN_UP_DATA, body);
  },

  login(body) {
    return httpCommon.post(API_CONSTANT.LOGIN_DATA, body);
  },

  forgetPassword(body) {
    return httpCommon.post(API_CONSTANT.FORGET_PASSWORD, body);
  },
  verifyCode(body) {
    return httpCommon.post(API_CONSTANT.VERIFY_CODE, body);
  },
  verifyToken(body) {
    return httpCommon.post(API_CONSTANT.VERIFY_TOKEN, body);
  },

  resendCode(body) {
    return httpCommon.post(API_CONSTANT.RESEND_CODE, body);
  },

  isValidOtp(body) {
    return httpCommon.put(API_CONSTANT.ISVALID_OTP, body);
  },

  resetPassword(body) {
    return httpCommon.put(API_CONSTANT.RESET_PASSWORD, body);
  },

  editUserData(body) {
    return httpCommon.put(API_CONSTANT.EDIT_USER_DATA, body);
  },

  createPayment(body) {
    return httpCommon.post(API_CONSTANT.CREATE_PAYMENT, body);
  },

  getUserData(body) {
    return httpCommon.post(API_CONSTANT.ALL_USER_DATA, body);
  },

  addFeedback(body) {
    return httpCommon.post(API_CONSTANT.ADD_FEEDBACK, body);
  },
  paymentStatus(body) {
    return httpCommon.post(API_CONSTANT.PAYMENT_STATUS, body);
  },
  fetchCustomerPayments(body) {
    return httpCommon.post(API_CONSTANT.USER_ALL_PAYMENT_DATA, body);
  },
  sendInquiry(body) {
    return httpCommon.post(API_CONSTANT.INQUIRY, body);
  },
};

export default userService;
