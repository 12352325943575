import React from "react";
import email from "../../svg/email.svg";
import phone from "../../svg/phone.svg";

const TermsOfService = () => {
  return (
    <div className="container">
      <div className="login-main-container" style={{ display: "block" }}>
        <div>
          <h1 style={{ color: "#333" }}>Terms Of Service</h1>

          <h1 style={{ color: "#333", fontSize: "30px" }}>
            Acceptance of Terms
          </h1>
          <p style={{ color: "#777" }}>
            Welcome to Pixerfect Solutions LLP. By accessing or using our
            website Assistree and the services provided therein, you agree to
            comply with and be bound by these Terms of Service. If you do not
            agree to these Terms, please refrain from using our website.
          </p>

          <h1 style={{ color: "#333", fontSize: "30px" }}>
            Description of Service
          </h1>
          <p style={{ color: "#777" }}>
            We have developed a Chrome extension designed to assist users in
            generating AI-based proposals for their clients, enabling them to
            create flawless, automatically generated proposals to enhance their
            business growth. Given that we provide AI services, there is a fee
            associated with our offering, and we offer various subscription
            plans through Stripe. These plans allow users to acquire additional
            credits for generating more AI proposals. Think of it as a prepaid
            model: when users deplete their credits to zero, they can make a
            payment to replenish their credits and continue utilizing the
            service.
          </p>
        </div>

        <div>
          <h1 style={{ color: "#333", fontSize: "30px" }}>
            Payment and Billing
          </h1>
          <div style={{ padding: "5px 30px" }}>
            <h1 style={{ fontSize: "25px" }}>Payment Method:</h1>
            <p style={{ color: "#777" }}>
              We use Stripe as our payment gateway to process all payments. By
              using our services, you agree to comply with Stripe's Terms of
              Service.
            </p>
            <h1 style={{ fontSize: "25px" }}>Pricing:</h1>
            <p style={{ color: "#777" }}>
              The pricing for our services is clearly stated on our website
              Assistree.com and is subject to change at our discretion. Any
              changes to pricing will be communicated in advance.
            </p>
            <h1 style={{ fontSize: "25px" }}>Payment Plans:</h1>
            <p style={{ color: "#777" }}>
              We offer various payment plans, each with its own set of features
              and pricing. You may choose the plan that best suits your needs.
            </p>
          </div>

          <h1 style={{ color: "#333", fontSize: "30px" }}>
            Refund and Cancellation Policy
          </h1>
          <div style={{ padding: "5px 30px" }}>
            <h1 style={{ fontSize: "25px" }}>Cancellation:</h1>
            <p style={{ color: "#777" }}>
              You may cancel your subscription at any time. Once canceled, your
              subscription will not renew, and you will retain access to our
              services until the end of the current billing cycle.
            </p>
            <h1 style={{ fontSize: "25px" }}>Refunds:</h1>
            <p style={{ color: "#777" }}>
              We offer a 7-day money-back guarantee for new subscribers. If you
              are not satisfied with our services, you may request a refund
              within 7 days of your initial purchase. Full refund only will
              applicable if user not consumed the credits more then 20%. It
              should be equal to or less then 20% consumed for particular
              purchase.
            </p>

            <h1 style={{ fontSize: "25px" }}>Refund Process:</h1>
            <p style={{ color: "#777" }}>
              To request a refund, please contact our customer support team at
              <img src={email} alt="Email:" style={{ height: "15px" }} />
              info@assistree.com or{" "}
              <img src={phone} alt="phone:" style={{ height: "15px" }} />
              +91 63556 23396. Refunds will be processed in accordance with our
              refund policy.
            </p>
          </div>

          <h1 style={{ color: "#333", fontSize: "30px" }}>User Conduct</h1>
          <p style={{ color: "#777" }}>
            By using our services, you agree not to engage in any prohibited
            activities, including but not limited to: <br />
            Violating any applicable laws or regulations.
            <br />
            Impersonating another person or entity.
          </p>

          <h1 style={{ color: "#333", fontSize: "30px" }}>Privacy Policy</h1>
          <p style={{ color: "#777" }}>
            Our Privacy Policy governs the collection and use of user
            information. Please review our Privacy Policy to understand how we
            handle your data.
          </p>
          <h1 style={{ color: "#333", fontSize: "30px" }}>Termination</h1>
          <p style={{ color: "#777" }}>
            We reserve the right to terminate or suspend your access to our
            services at our discretion, without notice, for any violation of
            these Terms.
          </p>
          <h1 style={{ color: "#333", fontSize: "30px" }}>Changes to Terms</h1>
          <p style={{ color: "#777" }}>
            We may update these Terms from time to time. Any changes will be
            posted on our website, and it is your responsibility to review them
            periodically. Your continued use of our services after the posting
            of changes constitutes acceptance of those changes.
          </p>
          <h1 style={{ color: "#333", fontSize: "30px" }}>Contact Us</h1>
          <p style={{ color: "#777" }}>
            If you have questions or concerns about our Privacy Policy, please
            contact us at
            <img src={email} alt="Email:" style={{ height: "15px" }} />
            <span>info@assistree.com.</span>
          </p>
          <p style={{ color: "#777" }}>
            By using our services, you acknowledge that you have read,
            understood, and agree to the practices described in this Privacy
            Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
