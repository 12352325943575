import enhanceWithChrome from "../svg/platform (2) 1.svg";
import messageWithAi from "../svg/web (2) 1.svg";
import onClickproposal from "../svg/proposal (2) 1.svg";
import meastro from "../svg/procurement (2) 1.svg";

/*Services SVG*/
import webdeveloper from "../svg/web-programming (1) 1.svg";
import uiux from "../svg/computer-graphic (1) 1.svg";
import graphic from "../svg/graphic-designer (1) 1.svg";
import content from "../svg/content-writing (1) 1.svg";
import marketing from "../svg/shopping-online (1) 1.svg";
import admin from "../svg/technical-support (1) 1.svg";
import account from "../svg/account (1) 1.svg";
import translator from "../svg/translator (1) 2.svg";

export const BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const API_CONSTANT = {
  /* Login Service*/
  SIGN_UP_DATA: "api/user/signup",
  LOGIN_DATA: "api/user/signin",
  FORGET_PASSWORD: "api/user/forgetPassword",
  VERIFY_CODE: "api/user/verifyOtp",
  RESEND_CODE: "api/user/resendVerificationCode",
  VERIFY_TOKEN: "api/user/validateToken",
  ISVALID_OTP: "api/user/isValidateForResetPassword",
  RESET_PASSWORD: "api/user/resetPassword",

  /* DashBoard Service*/
  EDIT_USER_DATA: "api/user/editUserDetails",
  ALL_USER_DATA: "api/user/getUserById",

  /* Payment Service*/
  CREATE_PAYMENT: "api/payment/createPayment",
  PAYMENT_STATUS: "api/payment/submitPayment",
  USER_ALL_PAYMENT_DATA: "api/payment/fetchPayments",

  /*Add feedback*/
  ADD_FEEDBACK: "api/feedback/newFeedback",

  /*Inqury*/
  INQUIRY: "api/inquiry/createInquiry",
};

export const FEATURES_DATA = {
  Features: [
    {
      icon: enhanceWithChrome,
      title: "One-Click Proposals",
      text: "Elevate Your Upwork Experience with 'One-Click Proposals': A Revolutionary Feature Tailored to Simplify and Supercharge Your Freelancing Journey!",
    },
    {
      icon: messageWithAi,
      title: "AI-Powered Proposal Maestro",
      text: "Unlock Tailored Cover Letters Instantly with AI Cover Letter Maestro! Effortlessly Shine Bright and Leave a Lasting Impression with Your Irresistible Cover Letter",
    },
    {
      icon: onClickproposal,
      title: "Ai Powers Messages & Reply",
      text: "AI Powers Messages & Replies streamlines your Upwork communication, making it smarter, more efficient, and user-friendly",
    },
    {
      icon: meastro,
      title: "Boost Chrome with Our Extension!",
      text: "Unlock Expert Freelancing Insights with Your Personal AI Mentor! Tailored Advice for Your Skills and Experience, Supercharging Your Freelance Success!",
    },
  ],
};

export const SERVICES_DATA = {
  Services: [
    {
      svg: webdeveloper,
      name: "Web Developers",
    },

    {
      svg: graphic,
      name: "Graphic Designers",
    },
    {
      svg: translator,
      name: "Translators",
    },
    {
      svg: content,
      name: "Content Writers",
    },
    {
      svg: marketing,
      name: "Sales & Marketing",
    },
    {
      svg: uiux,
      name: "Web/UI/UX Designers",
    },
    {
      svg: admin,
      name: "Admin Support",
    },
    {
      svg: account,
      name: "Accountants & Consultants",
    },
  ],
};
