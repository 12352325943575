import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import aerrow from "../svg/expand-svgrepo-com.svg";
import navLogo from "../svg/main-logo.svg";
import { notifySuccess, stringAvatar } from "../utils/common.service";

import { AppContext } from "../App.jsx";

const Navigation = (props) => {
  const { profileImage, firstName, lastName } = useContext(AppContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("apiToken");
  function loginHandler() {
    if (token) {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("email");
      navigate("/login");
      notifySuccess("User have been logged Out!!!");
    } else if (!token) {
      navigate("/login");
    }
  }
  function mainlogoclickhandler() {
    navigate("/");
  }
  const onClickFeatures = () => {
    navigate("/#features");
  };
  const onClickServices = () => {
    navigate("/#services");
  };
  const onClickPurchase = () => {
    navigate("/#BrowsePlan");
  };
  const onClickReview = () => {
    navigate("/#review");
  };
  const onClickAbout = () => {
    navigate("/#about");
  };
  const onClickDownloadExtension = () => {
    navigate("/#chromeExtension");
  };
  const dashboardLinkHandler = () => {
    navigate("/userDashboard#profile");
    window.scrollTo(0, 0);
  };

  const isMobileScreen = () => window.innerWidth <= 768;

  const closeMobileMenu = () => {
    const navbarToggle = document.getElementById(
      "bs-example-navbar-collapse-1"
    );
    if (navbarToggle) {
      navbarToggle.classList.remove("in");
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="top-banner">
        <p>
          Supercharge Your Upwork Proposals with Assistree, Now Enhanced by GPT!
        </p>
        <a
          className="top-banner-button"
          href="#BrowsePlan"
          onClick={onClickPurchase}
        >
          Click Now for a Deeper Dive!
        </a>
      </div>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll "
            href="#page-top"
            onClick={mainlogoclickhandler}
          >
            <img
              src={navLogo}
              alt="AssisTree"
              style={{ height: "40px", marginTop: "-8px" }}
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#features"
                className="page-scroll"
                onClick={(e) => {
                  onClickFeatures(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                Features
              </a>
            </li>

            <li>
              <a
                href="#services"
                className="page-scroll"
                onClick={(e) => {
                  onClickServices(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#BrowsePlan"
                className="page-scroll"
                onClick={(e) => {
                  onClickPurchase(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="page-scroll"
                onClick={(e) => {
                  onClickReview(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                Reviews
              </a>
            </li>
            <li>
              <a
                href="#chromeExtension"
                className="page-scroll"
                onClick={(e) => {
                  onClickDownloadExtension(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                Download
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="page-scroll"
                onClick={(e) => {
                  onClickAbout(e);
                  if (isMobileScreen()) {
                    closeMobileMenu();
                  }
                }}
              >
                About
              </a>
            </li>

            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={closeMobileMenu}
              >
                Contact
              </a>
            </li>
            {!token ? (
              <li>
                <a
                  href=""
                  className="page-scroll login-logout-btn"
                  onClick={loginHandler}
                >
                  Login
                </a>
              </li>
            ) : (
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="large"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      className="menu-profile userprofile-redirect"
                      aria-expanded={open ? "true" : undefined}
                      disableRipple
                    >
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="profile-icon"
                          className="profile-img-navbar"
                        />
                      ) : (
                        <Avatar
                          {...stringAvatar(
                            lastName == null
                              ? `${firstName}`
                              : `${firstName} ${lastName}`
                          )}
                          className="user-icon"
                          sx={{
                            width: 40,
                            height: 40,
                            fontSize: "17px",
                            background:
                              "linear-gradient(to right, #297f05f7 0%, #3ac500f7 100%)",
                          }}
                        />
                      )}
                      <img
                        src={aerrow}
                        alt="aerrow"
                        style={{ height: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  className="menu"
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={dashboardLinkHandler}>
                    <ListItemIcon>
                      <Settings fontSize="large" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={loginHandler}>
                    <ListItemIcon>
                      <Logout fontSize="large" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
