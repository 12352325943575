import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserProfile from "./UserProfile";
import "./UserDashboard.css";
import Navigation from "../navigation";
import Contact from "../contact";
import "../BrowsePlans.css";
import FeedBackForm from "../FeedBack/FeedBackForm";
import Payment from "../Payment/Payment";
import { Grid } from "@mui/material";
import UserPlans from "./userPlan";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function UserDashboard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-container">
      <Navigation />
      <Grid className="container">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "auto",
            width: "max-content",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab className="tab-items" label="Profile" {...a11yProps(0)} />

            <Tab className="tab-items" label="Plans" {...a11yProps(1)} />

            <Tab
              className="tab-items"
              label="Payment Records"
              {...a11yProps(2)}
            />

            <Tab className="tab-items" label="Feedback" {...a11yProps(3)} />
          </Tabs>
          <TabPanel
            id="profile"
            className="tab-panel-container"
            value={value}
            index={0}
          >
            <UserProfile />
          </TabPanel>
          <TabPanel
            id="plans"
            value={value}
            index={1}
            className="pricing-container"
            
          >
            <UserPlans />
          </TabPanel>
          <TabPanel
            id="paymentDetails"
            className="tab-panel-container"
            value={value}
            index={2}
          >
            <Payment />
          </TabPanel>

          <TabPanel
            id="feedback"
            className="tab-panel-container"
            value={value}
            index={3}
          >
            <FeedBackForm />
          </TabPanel>
        </Box>
      </Grid>
      <Contact />
    </div>
  );
}

export default UserDashboard;
