import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import PricingPage from "./PricingPage";

const stripePromise = loadStripe(
  "pk_test_51NquuuSFU6SHRzFeoajd0Mn1pWGbXCAiEVAagxw32qn50RllrIa9i16FKsjuT7gLxmOUs18o2xDEzQ3dmTOrxQC600vhiTvdJH"
);

const PaymentOut = () => {
  const options = {
    clientSecret:
      "sk_test_51NquuuSFU6SHRzFeQEuyHD2dT1XFejmBdyzuKOeoziMPLoU0BMkR6wN8YMneBu7SO53AJmBSbW7g8M3D06tPvwDA00ijEIcchl",
  };

  return (
    <div className="App pricing-table" stripe={stripePromise} options={options}>
      <PricingPage />
    </div>
  );
};

export default PaymentOut;
