import React, { createContext, useEffect, useState } from "react";
import RootLayout from "./components/root/rootLayout";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import Login from "./components/login/login";
import HomePage from "./components/HomePage";

import SignUp from "./components/signUp/signUp";
import ForgetPassWord from "./components/ForgetPassword/ForgetPass";
import ForgetPasswordVerification from "./components/Verification.jsx/ForgetPasswordVerification";
import UserVerification from "./components/Verification.jsx/userVerification";
import SetPassword from "./components/ForgetPassword/SetPassword/SetPassword";
import { Toaster } from "react-hot-toast";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import TermsOfService from "./components/PolicyandService/TermsOfService";
import PrivacyPolicy from "./components/PolicyandService/PrivacyPolicy";
import jwt_decode from "jwt-decode";
import userService from "./service/service";
import { notifyError } from "./utils/common.service";

const router = createBrowserRouter([
  {
    path: "",
    element: <RootLayout />,
    id: "root",
    children: [
      {
        path: "/",
        element: <HomePage />,
        id: "homepage",
      },
      {
        path: "login",
        element: <Login />,
        id: "login",
      },
      {
        path: "/sign-up",
        element: <SignUp />,
        id: "signUp",
      },
      {
        path: "/forget_password",
        element: <ForgetPassWord />,
        id: "forgetPassword",
      },
      {
        path: "/user_verification",
        element: <UserVerification />,
        id: "user-verification",
      },
      {
        path: "/forget_password/verification",
        element: <ForgetPasswordVerification />,
        id: "forgetPassword-verification",
      },
      {
        path: "/setPassword",
        element: <SetPassword />,
        id: "setPassword",
      },
      {
        path: "/termsofservice",
        element: <TermsOfService />,
        id: "TermsOfService",
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy />,
        id: "PrivacyPolicy",
      },
    ],
  },
  {
    path: "/userDashboard",
    element: <UserDashboard />,
    id: "userDashboard",
  },
  {
    path: "/Payment_Success",
    element: <PaymentSuccess />,
    id: "Payment-Success",
  },
]);

const initialCount = null;
export const AppContext = createContext({
  profileImage: initialCount,
  firstName: "User",
  lastName: "",
});
function App() {
  const [tokenData, setTokenData] = useState({});

  const [profileImage, setProfileImage] = useState(initialCount);

  const [firstName, setFirstName] = useState(initialCount);
  const [lastName, setLastName] = useState(initialCount);

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("apiToken");
    if (token) {
      try {
        userService
          .verifyToken({ token: token })
          .then((res) => {
            if (res.status !== 200) {
              notifyError(res.message);
              localStorage.removeItem("apiToken");
            } else {
              const decode = jwt_decode(token);
              setTokenData(decode);
              if (decode?._id) {
                getFetchData();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const getFetchData = async () => {
    try {
      const obj = {
        _id: tokenData?._id,
      };

      const userData = await userService.getUserData(obj);
      if (userData && userData.status === 200) {
        setProfileImage(userData?.image?.attachment?.url);
        setFirstName(
          userData.firstName ? userData.firstName : userData.username
        );
        setLastName(userData.lastName ? userData.lastName : null);
      }
    } catch (error) {}
  };

  return (
    <>
      <AppContext.Provider
        value={{
          profileImage,
          setProfileImage,
          firstName,
          setFirstName,
          lastName,
          setLastName,
        }}
      >
        <Toaster toastOptions={toasterOptions} />
        <RouterProvider router={router} />
      </AppContext.Provider>
    </>
  );
}

export default App;
