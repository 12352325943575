import React from "react";
import AutoPlay from "./AutoPlay";

const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <span className="header-freestyle">
                  Unlock 5 Credits of Exclusive Free Access – Limited-Time
                  Offer!{" "}
                  <img
                    src="https://tse3.mm.bing.net/th?id=OIP.vdq8mhqETBP94GkaHkOxuwHaHa&pid=Api&P=0&h=180"
                    height="30px"
                    style={{ marginInline: "8px", height: "30px" }}
                  />
                </span>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>

                <a href="#chromeExtension" className="header-button">
                  Get Your Complimentary Trial Now - Install Extension!
                </a>
                <p className="credit-not-need">
                  {" "}
                  Experience Our Premium Service, No Credit Card Required!
                </p>
              </div>
            </div>
            <AutoPlay />
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
