import React from "react";

const About = (props) => {
  const aboutData = (
    <>
      <h4>Unlock the Power of Personalized Cover Letters with Assistree</h4>
      <p>
        Are you a freelancer looking to boost your chances of landing the
        perfect job on Upwork? Look no further! Assistree, your ultimate
        productivity tool, is here to streamline your job application process
        like never before. With Assistree, you can effortlessly generate
        personalized cover letters in a matter of seconds. Thanks to our
        cutting-edge AI technology, your cover letters will stand out from the
        crowd, increasing your chances of getting hired faster than ever.
      </p>
      <h4>Why Assistree?</h4>
      <p>
        <span style={{ fontWeight: "bold" }}>Efficiency:</span> Say goodbye to
        hours spent writing cover letters from scratch. Assistree does the heavy
        lifting for you, allowing you to focus on what you do best.
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>User-Friendly:</span> Our intuitive
        design and user-friendly interface make navigating Assistree a breeze.
        No tech headaches here!
      </p>
    </>
  );
  return (
    <div id="about" style={{ paddingTop: "135px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? aboutData : "loading..."}</p>

              <div className="list-style">
                <div style={{ marginTop: "20px" }}>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
