import React from "react";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import { useState } from "react";
import { useEffect } from "react";
import Header from "./header";
import Features from "./features";
import Services from "./services";
import Team from "./Team";
import Testimonials from "./testimonials";
import About from "./about";
import CromeExtension from "./CromeExtension";
import { useSearchParams } from "react-router-dom";
import MainPopUp from "../components/MainPopUp/MainPopUp";
import CustomizationDialog from "../utils/CustomizationModal";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  window.onload = () => {
    if (searchParams.get("showPopUp") === "true") {
      setOpenModal(true);
    } else if (searchParams.get("showPopUp") === "false") {
      setOpenModal(false);
    } else {
      setTimeout(() => {
        setOpenModal(true);
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={<MainPopUp closeForm={handleCloseModal} />}
      />
      <div className="main-div-homepage">
        <Header data={landingPageData.Header} />
        <Features data={landingPageData.Features} />

        <Services data={landingPageData.Services} />

        <Team data={landingPageData.Team} />
        <Testimonials data={landingPageData.Testimonials} />
        <CromeExtension />
        <About data={landingPageData.About} />
      </div>
    </>
  );
};

export default HomePage;
