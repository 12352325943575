import React from "react";
import CreatePayment from "../Payment/CreatePayment";

const UserPlans = () => {
  return (
    <div>
      <div className="text-center section-title">
        <h2>🚀 Choose Your Ideal Plan</h2>
        <p
          className="p-text"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          Explore All of Assistree's Features with 5 Free credit – No Extra Cost
          Required!
        </p>
      </div>
      <div id="login-container">
        <CreatePayment />
      </div>
    </div>
  );
};

export default UserPlans;
