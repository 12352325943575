import React, { useEffect, useState } from "react";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import userService from "../../service/service";
import "../ForgetPassword/ForgetPassword.css";
import "./verification.css";
import OtpInput from "react-otp-input";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { LoadingButton } from "@mui/lab";

const UserVerification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const email = localStorage.getItem("email");

  const initialValues = {
    otp: "",
    submit: null,
  };

  const { errors, touched, handleBlur, handleSubmit } = useFormik({
    initialValues,

    onSubmit: async () => {
      try {
        const obj = {
          email: email,
          otp: otp.toString() || "",
        };
        const registerUser = await userService.verifyCode(obj);

        if (registerUser && registerUser.status === 200) {
          navigate("/login");
          setLoading(false);
        } else {
          notifyError("Invalid OTP");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
  });

  function resendCodeHandler() {
    const obj = {
      email: email,
    };
    userService.resendCode(obj).then((res) => {
      if (res && res.status === 200) {
        notifySuccess("Verification mail sent successfully");
        setLoading(false);
      } else {
        setLoading(false);
        notifyError("Error Resend Code");
      }
    });
  }
  return (
    <div>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form form-verify">
          <h2>Verify Your Account</h2>
          <div className="form-group">
            <label className="label-login form-label">
              Enter Code Which you Get in Your E-mail
            </label>
            <strong style={{ marginLeft: "150px" }}>{email}</strong>
            <div className="input-verify">
              <OtpInput
                name="otp"
                onBlur={handleBlur}
                value={otp || ""}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />

              {touched.otp && errors.otp && (
                <div className="input-error">{errors.otp}</div>
              )}
            </div>
          </div>

          <div className="btn-login-submit btn-code">
            <LoadingButton
              type="submit"
              loading={loading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="common-button"
            >
              {loading ? "" : "Verify"}
            </LoadingButton>
          </div>
          <div className="link-signup" style={{ justifyContent: "center" }}>
            <h3 className="h3-forget">Don't get an Code?</h3>

            <h3 className="btn-signup h3-forget" onClick={resendCodeHandler}>
              Resend Code
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserVerification;
