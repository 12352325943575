import React from "react";

const AutoplayVideo = () => {
  return (
    <div>
      <iframe
        className="introVideo"
        title="youtube"
        width="100%"
        src="https://www.youtube.com/embed/iVfT1rZDNxk?si=A5CHroJ9api57JPuA5CHroJ9api57JPu?privacy-enhanced=true&amp;controls=0&amp;start=0&showinfo=0&rel=0&vq=hd1080&modestbranding=1"
        playerVars={{ vq: "hd1080" }}
        frameborder="0"
        autoplay
        allow="autoplay; fullscreen;"
        allowFullScreen
        loop
      ></iframe>
    </div>
  );
};

export default AutoplayVideo;
