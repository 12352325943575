import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import userService from "../service/service";
import { notifyError, notifySuccess } from "../utils/common.service";

const Contact = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    message: "",
    submit: null,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Email is required!")
      .max(255)
      .required("Email is required"),
    message: Yup.string().required("Please enter a message"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          setLoading(true);
          const registerUser = await userService.sendInquiry({
            name: values?.name || "",
            email: values?.email || "",
            message: values?.message || "",
          });

          if (registerUser && registerUser.status === 200) {
            notifySuccess(
              "Your message sent to Admin, Please wait for their reply"
            );
            navigate("/#page-top");
            setLoading(false);
          } else {
            setLoading(false);
            notifyError("Something went wrong, please try again");
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });
  const date = new Date();
  const currentYear = date.getFullYear();

  const helpClickHandler = () => {
    notifySuccess(
      "Kindly utilize our website's integrated chatbot, located in the lower-right corner."
    );
  };
  return (
    <div>
      <div id="contact" style={{ paddingTop: "110px" }}>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "rgba(255, 255, 255, 0.75)",
                  }}
                >
                  assistree.help@gmail.com
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-div-option">
            <div>
              <p className="footer-option-head">Get Assistree</p>
              <p>
                <a
                  className="footer-div-option-a"
                  href="https://chromewebstore.google.com/detail/assistree/kbkcpibmagpfbkjhloipdkihakcpkall"
                  target="_blank"
                >
                  Assistree for Chrome
                </a>
              </p>
            </div>
            <div>
              <p className="footer-option-head">Product</p>

              <p>Upwork Proposal Writing Assistant</p>
            </div>
            <div>
              <p className="footer-option-head">Company</p>

              <p>
                <a href="#testimonials" className="footer-div-option-a">
                  Reviews
                </a>
              </p>

              <p>
                <a
                  href="#contact"
                  onClick={helpClickHandler}
                  className="footer-div-option-a"
                >
                  Help & Support
                </a>
              </p>
            </div>
            <div>
              <p className="footer-option-head">Connect</p>
              <ul className="social">
                <li>
                  <a href={props.data ? props.data.facebook : "/"}>
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.twitter : "/"}>
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.youtube : "/"}>
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div>&copy; {currentYear} By ASSISTREE</div>
            <div className="footer-policy-tab">
              <a href="/termsofservice" className="footer-link">
                Terms of Service
              </a>
              <a href="/privacypolicy" className="footer-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
