import React, { useContext } from "react";
import { AppContext } from "../../App.jsx";

import "./UserDashboard.css";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import "../login/login.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import userService from "../../service/service";
import {
  notifyError,
  notifySuccess,
  stringAvatar,
} from "../../utils/common.service";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import phone from "../../svg/phone.svg";
import email from "../../svg/email.svg";
import whtsapp from "../../svg/whatsapp-social-media-svgrepo-com.svg";
import edit from "../../svg/circum_edit.svg";
import { Avatar, Tooltip } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState("male");
  const [tokenData, setTokenData] = useState({});
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [editIcon, setEditIcon] = useState(true);
  const [userProfileData, setUserProfileData] = useState(false);
  const {
    profileImage,
    setProfileImage,
    firstName,
    setFirstName,
    lastName,
    setLastName,
  } = useContext(AppContext);

  useEffect(() => {
    const token = localStorage.getItem("apiToken");
    if (token) {
      const decode = jwt_decode(token);
      setTokenData(decode);
      if (decode?._id) {
        getFetchData();
      }
    } else {
      navigate("/login");
    }
  }, []);

  const getFetchData = async () => {
    try {
      setLoading(true);
      const obj = {
        _id: tokenData?._id,
      };

      const userData = await userService.getUserData(obj);
      if (userData && userData.status === 200) {
        setLoading(false);
        setResData(userData);
        setUserProfileData(true);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setProfileImage(userData?.image?.attachment?.url);

        notifySuccess("User Data fetched successfully");
      } else {
        setLoading(false);
        notifyError("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    firstName: Yup.string().required("FirstName is required"),
    lastName: Yup.string().required("LastName is required"),

    phoneNumber: Yup.string()
      .min(6, "Invalid phone number")
      .max(10, "Invalid phone number")
      .required("Phone number is required"),
    whatsappNumber: Yup.string()
      .min(6, "Invalid phone number")
      .max(10, "Invalid phone number")
      .required("Phone number is required"),
  });
  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const initialValues = {
    username: resData.username || "",
    firstName: resData.firstName || "",
    lastName: resData.lastName || "",
    phoneNumber: resData.phoneNumber || "",
    whatsappNumber: resData.whatsappNumber || "",
    selectedGender: "",
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (imageFile) {
        formData.append("attachment", imageFile);
      }
      formData.append("user_id", tokenData?._id);
      formData.append("username", values?.username);
      formData.append("firstName", values?.firstName || "");
      formData.append("lastName", values?.lastName || "");
      formData.append("phoneNumber", values?.phoneNumber || "");
      formData.append("whatsappNumber", values?.whatsappNumber || "");
      formData.append("gender", selectedGender);

      const registerUser = await userService.editUserData(formData);

      if (registerUser && registerUser.status === 200) {
        setLoading(false);
        notifySuccess("User saved successfully, please verify Your Data");
        setProfileImage(resData?.image?.attachment?.url);
        setUserProfileData(true);
        setEditUser(!editUser);
        setEditIcon(!editIcon);
        window.scrollTo(0, 0);
        getFetchData();
      } else {
        setLoading(false);
        notifyError("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const toggleEditUser = () => {
    setEditUser(!editUser);
    setEditIcon(!editIcon);
    window.scrollTo(0, 0);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  return (
    <div className="text-center">
      <div className="section-title">
        <h2 style={{ margin: "0" }}>User Profile</h2>
      </div>
      {!userProfileData ? (
        <Stack direction="column" spacing={2} className="user-profile-card">
          <Stack
            direction="row"
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <div className="user-card-top">
              <Stack direction="column" spacing={2}>
                <Skeleton variant="circular" width={100} height={100} />
                <Skeleton width={100} height={20} />
              </Stack>
            </div>
            <div className="user-detail">
              <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                  <Skeleton variant="rectangular" width={24} height={24} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton width={100} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton width={100} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton width={100} />
                </Stack>
              </Stack>
            </div>
          </Stack>
          <div className="user-card-bottom">
            <Stack direction="row" spacing={2}>
              <div>
                <p className="user-name-main">Used Credit</p>
                <Skeleton width={150} height={60} alignItems="center" />
              </div>
              <div className="vertical"></div>
              <div>
                <p className="user-name-main">Remaining Credit</p>
                <Skeleton width={150} height={60} alignItems="center" />
              </div>
            </Stack>
          </div>
        </Stack>
      ) : (
        <div className="user-profile-card">
          <div className="user-card-top">
            <div>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="profile-icon"
                  className="profile-img"
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    lastName == null
                      ? `${firstName}`
                      : `${firstName} ${lastName}`
                  )}
                  className="user-icon"
                  sx={{
                    height: "100px",
                    width: "100px",
                    fontSize: "30px",
                    background:
                      "linear-gradient(to right, #297f05f7 0%, #3ac500f7 100%)",
                  }}
                />
              )}

              <p
                style={{
                  margin: "20px auto",
                  fontWeight: "600",
                  fontSize: "15px",
                }}
              >
                {resData.username}
              </p>
            </div>
            <div className="user-detail">
              <div className="user-detail-data">
                {resData.firstName ? (
                  <span className="user-name-main">{resData.firstName}</span>
                ) : (
                  <span className="user-name-main">{resData.username}</span>
                )}

                {resData.lastName && (
                  <span className="user-name-main">{resData.lastName}</span>
                )}
                {editIcon ? (
                  <Tooltip title="Edit Detail" placement="top" arrow>
                    <img
                      className="detail-svg"
                      src={edit}
                      alt="Edit"
                      onClick={toggleEditUser}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                ) : (
                  " "
                )}
              </div>
              <div className="user-detail-data">
                <img className="detail-svg" src={email} alt="Email:" />
                <h4 style={{ color: "#777" }}>{resData.email}</h4>
              </div>
              {resData.phoneNumber && (
                <div className="user-detail-data">
                  <img className="detail-svg" src={phone} alt="Phone Number" />
                  <h4 style={{ color: "#777" }}>{resData.phoneNumber}</h4>
                </div>
              )}
              {resData.whatsappNumber && (
                <div className="user-detail-data">
                  <img
                    className="detail-svg"
                    src={whtsapp}
                    alt="Whatsapp Number:"
                  />
                  <h4 style={{ color: "#777" }}>{resData.whatsappNumber}</h4>
                </div>
              )}
            </div>
          </div>
          <div className="user-card-bottom">
            <div>
              <p className="user-name-main">Used Credit</p>

              <p className="used-credit">{resData.totalUsedRequests || "0"} </p>
            </div>
            <div className="vertical"></div>
            <div>
              <p className="user-name-main">Remaining Credit</p>
              <p className="remain-credit">{resData.remainedCredit || "0"}</p>
            </div>
          </div>
        </div>
      )}

      <div>
        {editUser ? (
          <div id="login-container" style={{ marginTop: "50px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="edit-form"
                  style={{
                    boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <h2>Edit User Details</h2>
                  <div className="form-group">
                    <label className="label-login">User Icon:</label>
                    <Field
                      className="input-login input-profile-image"
                      name="attachment"
                      type="file"
                      id="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleChangeImage}
                    />
                    <ErrorMessage
                      name="userIcon"
                      component="div"
                      className="input-error"
                    />
                    <label className="label-login">UserName:</label>
                    <Field
                      className="input-login"
                      name="username"
                      type="text"
                      label="UserName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="input-error"
                    />

                    <label className="label-login">FirstName:</label>
                    <Field
                      className="input-login"
                      name="firstName"
                      type="text"
                      label="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="input-error"
                    />
                    <label className="label-login">Last Name: </label>
                    <Field
                      className="input-login"
                      name="lastName"
                      type="text"
                      label="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="input-error"
                    />

                    <label htmlFor="phoneNumber" className="label-login">
                      Phone Number:{" "}
                    </label>
                    <Field
                      className="input-login"
                      name="phoneNumber"
                      type="number"
                      label="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="input-error"
                    />

                    <label htmlFor="whatsappNumber" className="label-login">
                      Whatsapp Number:{" "}
                    </label>
                    <Field
                      className="input-login"
                      name="whatsappNumber"
                      type="number"
                      label="whatsappNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.whatsappNumber}
                    />
                    <ErrorMessage
                      name="whatsappNumber"
                      component="div"
                      className="input-error"
                    />
                  </div>
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="label-login"
                  >
                    Gender:
                  </label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="selectedGender"
                    onChange={handleGenderChange}
                    onBlur={handleBlur}
                    value={selectedGender}
                    sx={{ margin: "-7px 0 10px 60px" }}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>

                  <div className="editform-footer-div">
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      className="common-button"
                    >
                      {loading ? "" : "Save"}
                    </LoadingButton>
                    <button
                      type="button"
                      onClick={toggleEditUser}
                      className="cancel-btn"
                    >
                      Close
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserProfile;
