import React, { useState } from "react";

function PricingPage() {
  const email = localStorage.getItem("email");
  const priceTab = document.getElementById("pricing-table");
  priceTab?.addEventListener("click", (e) => {
    console.log("events", e.target);
  });

  return (
    <div
      onClick={() => {
        console.log("click");
      }}
    >
      {/* --------------------------------------- Test MODE PIXERFECT LLP STRIPE------------------------------ */}
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1O9jQVSFU6SHRzFex0UVqBcQ"
        publishable-key="pk_test_51NquuuSFU6SHRzFeoajd0Mn1pWGbXCAiEVAagxw32qn50RllrIa9i16FKsjuT7gLxmOUs18o2xDEzQ3dmTOrxQC600vhiTvdJH"
        id="pricing-table"
        customer-email={email}
        client-reference-id="{{CLIENT_REFERENCE_ID}}"
      ></stripe-pricing-table> */}
      {/* ------------------------------LIVE MODE PIXERFECT LLP
      STRIPE------------------------------------------------------ */}
      <stripe-pricing-table
        pricing-table-id="prctbl_1O9lk7SFU6SHRzFe2dnkat3s"
        publishable-key="pk_live_51NquuuSFU6SHRzFe8b8LddMjeMHsH9iW8U3WJkYWyBkhLkUaP2fyXTSdLMCf9uQpmp1NiUgRkhcocJAR03IiKaAr00w64DOSkz"
        customer-email={email}
        client-reference-id="{{CLIENT_REFERENCE_ID}}"
      ></stripe-pricing-table>
    </div>
  );
}

export default PricingPage;
