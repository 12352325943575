import React, { useState } from "react";
import "../login/login.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import userService from "../../service/service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { LoadingButton } from "@mui/lab";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { TextareaAutosize } from "@mui/material";

const FeedBackForm = () => {
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState({});
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  useEffect(() => {
    const token = localStorage.getItem("apiToken");
    const decode = jwt_decode(token);
    setTokenData(decode);
  }, []);

  const initialValues = {
    feedback: "",
    rating: rating,
    submit: null,
  };

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const registerUser = await userService.addFeedback({
            _id: tokenData?._id,
            feedback: values.feedback || "",
            rating: rating || "",
          });

          if (registerUser && registerUser.status === 200) {
            notifySuccess("Your valueable feedback has been Submitted");
            setLoading(false);
          } else if (registerUser && registerUser.status === 400) {
            setLoading(false);
            notifyError("You have already submitted feedback");
          } else {
            setLoading(false);
            notifyError(
              "Server is not responding, Please try after some time!"
            );
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });

  return (
    <div className="container feedback-containers">
      <div className="text-center section-title">
        <form noValidate onSubmit={handleSubmit} className="">
          <div className="section-title" style={{ marginBottom: "20px" }}>
            <h2 style={{ textAlign: "center" }}>Feedback Form</h2>
            <h3 className="feedback-text">Let's Us know, How we're doing!</h3>
            <h4 className="feedback-text">
              Thank you for using the best selling plan on your journey to
              Assistree, How can we make it best for you? let's us know your
              thoughts
            </h4>
            <h3 className="feedback-text" style={{ textAlign: "left" }}>
              Just fill out below form:
            </h3>
          </div>

          <label className="label-login" htmlFor="password">
            Feedback:
          </label>
          <TextareaAutosize
            disabled={false}
            minRows={6}
            className="input-login"
            name="feedback"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Tell us about need to implement to be successful"
            value={values.feedback || ""}
            size="lg"
            variant="outlined"
          />
          {touched.feedback && errors.feedback && (
            <div className="input-error">{errors.feedback}</div>
          )}
          <div className="rating-container">
            <h3 style={{ marginBottom: "0" }}>Rate this product:</h3>
            <div className="rating">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star ${value <= rating ? "filled" : ""}`}
                  style={{ fontSize: "50px" }}
                  onClick={() => handleRatingChange(value)}
                ></span>
              ))}
            </div>
            <p>You rated this product: {rating} stars</p>
          </div>

          <LoadingButton
            type="submit"
            loading={loading}
            loadingPosition="start"
            variant="contained"
            fullWidth
            className="common-button"
          >
            {loading ? "" : "Submit Feedback"}
          </LoadingButton>
        </form>
      </div>
    </div>
  );
};

export default FeedBackForm;
