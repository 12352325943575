import toast from "react-hot-toast";

export const userTokenMethod = (methodType, data) => {
  if (methodType === "set") {
    localStorage.setItem("apiToken", data);
  } else if (methodType === "get") {
    return localStorage.getItem("apiToken");
  } else if (methodType === "delete") {
    localStorage.removeItem("apiToken");
    localStorage.removeItem("email");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
  }
};

export const notifyError = (message) => toast.error(message);

export const notifySuccess = (message) => toast.success(message);

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  let initials;
  const nameParts = name.split(" ");

  if (nameParts.length > 1) {
    initials = `${nameParts[0][0]}${nameParts[1][0]}`;
  } else {
    initials = name.substring(0, 1); // Take first two characters if no space
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

export const capitalizeFirstLetter = (str, defaultValue = "-") => {
  if (!str) {
    return defaultValue;
  }
  return str.toUpperCase();
};

export const DateFormatSimple = (originalDate) => {
  const dateObject = new Date(originalDate);

  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const year = dateObject.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
