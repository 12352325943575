import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import userService from "../../service/service";
import jwt_decode from "jwt-decode";
import {
  DateFormatSimple,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
} from "../../utils/common.service";
import { Grid } from "@mui/material";
import "./PaymentSuccess.css";

const headCells = [
  {
    id: "PaymentId",
    label: "PaymentId",
  },
  {
    id: "CustomerId",
    label: "CustomerId",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "Date",
    label: "Date",
  },
  {
    id: "Selected-Plan",
    label: "Selected Plan",
  },
  {
    id: "credit",
    label: "Credit",
  },
  {
    id: "gst",
    label: "GST_TAX",
  },
  {
    id: "service",
    label: "Service_Tax",
  },
  {
    id: "Amount",
    label: "Amount",
  },
  {
    id: "currency",
    label: "Currency",
  },
  {
    id: "status",
    label: "Status",
  },
];
function EnhancedTableToolbar() {
  return (
    <Grid sx={{ display: "flex", alignItems: "baseline" }}>
      <Grid className="text-center section-title" sx={{ flex: "1 1 100%" }}>
        <h2 style={{ marginTop: "0", marginBottom: "20px" }}>Payment Record</h2>
      </Grid>
    </Grid>
  );
}

function EnhancedTableHead(props) {
  return (
    <TableHead sx={{ position: "sticky", top: "0", background: "white" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ fontSize: "15px", textAlign: "left" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Payment = () => {
  const [tokenData, setTokenData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("apiToken");
    const decode = jwt_decode(token);
    setTokenData(decode);

    fetchPaymentDetail();
  }, []);

  const fetchPaymentDetail = async () => {
    try {
      setLoading(true);

      const registerUser = await userService.fetchCustomerPayments({
        user_id: tokenData?._id,
      });

      if (registerUser && registerUser.status === 200) {
        setLoading(false);
        notifySuccess("Payment Data Fetched Success!");
        setPaymentData(registerUser.data);
      } else {
        setLoading(false);
        notifyError("Payment Data not Found!");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  let credit;
  if (paymentData[0]?.fromUser?.remainedCredit) {
    credit = paymentData[0]?.fromUser?.remainedCredit;
  } else {
    credit = paymentData[1]?.fromUser?.remainedCredit;
  }
  return (
    <Box>
      <EnhancedTableToolbar credit={credit} />

      {loading ? (
        <Grid className="loader"></Grid>
      ) : paymentData.length !== 0 ? (
        <Grid>
          <Typography
            variant="h4"
            sx={{ marginBottom: "10px" }}
            id="tableTitle"
            component="div"
          >
            {`Current Credits: ${credit}`}
          </Typography>

          <Paper>
            <TableContainer
              className="tableBody"
              sx={{ overflow: "scroll", maxHeight: "450px" }}
            >
              <Table>
                <EnhancedTableHead />
                <TableBody sx={{ position: "inherit" }}>
                  {paymentData.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?._id || "-"}
                        </TableCell>

                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?.customerId || "-"}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?.fromUser?.email || "-"}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {DateFormatSimple(row?.createdAt) || "-"}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "left",
                            minWidth: "130px",
                          }}
                        >
                          {row?.plan_type === "starter_spark" &&
                            "Starter Spark"}
                          {row?.plan_type === "silver_synapse" &&
                            "Silver Synapse"}
                          {row?.plan_type === "gold_genius" && "Gold Genius"}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "#369e0b",
                          }}
                        >
                          {row?.plan_type === "starter_spark" && "+5"}
                          {row?.plan_type === "silver_synapse" && "+20"}
                          {row?.plan_type === "gold_genius" && "+50"}
                        </TableCell>

                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?.gst_tax || "-"}
                        </TableCell>

                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?.service_tax || "-"}
                        </TableCell>

                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {row?.amount || "-"}
                        </TableCell>

                        <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                          {capitalizeFirstLetter(row?.currency) || "-"}
                        </TableCell>

                        {row?.status === "requires_action" ? (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              textAlign: "left",
                              color: "#fc5700",
                            }}
                          >
                            {row?.status || "-"}
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              textAlign: "left",
                              color: "#369e0b",
                            }}
                          >
                            {row?.status || "-"}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              fontSize: "20px",
            }}
            id="tableTitle"
            component="div"
          >
            {`Total Record: ${paymentData.length}`}
          </Typography>
        </Grid>
      ) : (
        <Grid>
          <p className="record-empty">You haven't made any payments yet.</p>
        </Grid>
      )}
    </Box>
  );
};

export default Payment;
