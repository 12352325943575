import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import userService from "../../service/service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import "./signup.css";

const GoogleWithSignUp = (props) => {
  const { successMessage, failMessage } = props;

  const navigate = useNavigate();

  const responseGoogle = async (credentialResponse) => {
    const details = jwt_decode(credentialResponse.credential);

    try {
      const obj = {
        firstName: details?.given_name,
        username: details?.given_name,
        lastName: details?.family_name,
        email: details?.email,
        mode: "google",
      };

      const response = await userService.register(obj);

      if (response && response.status === 200) {
        localStorage.setItem("apiToken", response?.user?.tokenLogin);
        localStorage.setItem("email", details?.email);

        navigate("/userDashboard");
        notifySuccess(successMessage);
      }
    } catch (error) {
      notifyError(failMessage);
    }
  };
  return (
    <>
      <div className="main-container" style={{ display: "none" }}>
        <GoogleOAuthProvider
          className="google-btn"
          clientId={process.env.REACT_APP_CLIENT_ID}
        >
          <GoogleLogin
            onError={() => {
              notifyError("Login Failed");
            }}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
        </GoogleOAuthProvider>
        <br />
      </div>
    </>
  );
};

export default GoogleWithSignUp;
