import React, { useState } from "react";
import { useFormik, Formik, Form, Field } from "formik";
import userService from "../../../service/service";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "../../login/login.css";
import "../../signUp/signup.css";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import loginLogo from "../../../svg/LOGO-LOGIN.svg";
import vectorTop from "../../../svg/Vector-top.png";
import vectorBottom from "../../../svg/vector-bottom.png";
import eyeoff from "../../../svg/eye-svgrepo-com.svg";
import eyeopen from "../../../svg/eye-off-svgrepo-com.svg";
import { Tooltip } from "@mui/material";

function SetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
    submit: null,
  };
  const email = localStorage.getItem("email");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password must be not empty"),
    confirmPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password must be not empty"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          const obj = {
            email: email,
            password: values.password || "",
          };

          const registerUser = await userService.resetPassword(obj);

          if (registerUser && registerUser.status === 200) {
            localStorage.removeItem("email");
            navigate("/login");
            setLoading(false);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
    });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div id="reSetPassword" className="container">
      <div className="login-main-container">
        <div
          className="login-logo login-left"
          style={{ position: "relative", height: "569px" }}
        >
          <img src={vectorTop} className="vector-top" />
          <img
            src={loginLogo}
            alt="AssisTree"
            style={{ height: "15rem", marginLeft: "150px" }}
          />
          <img src={vectorBottom} className="vector-bottom" />
        </div>
        <div className="login-container">
          <form className="login-form" noValidate onSubmit={handleSubmit}>
            <h2>Reset Password</h2>
            <div className="form-group">
              <label className="label-login">Password:</label>
              <div style={{ position: "relative" }}>
                <input
                  className="input-login"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <button
                  type="button"
                  onClick={toggleShowPassword}
                  className="show-password-button-signup"
                >
                  {showPassword ? (
                    <Tooltip title="Hide password">
                      <img
                        src={eyeopen}
                        alt="Hide Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show password">
                      <img
                        src={eyeoff}
                        alt="Show Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  )}{" "}
                </button>{" "}
              </div>

              {touched.password && errors.password && (
                <div className="input-error">{errors.password}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-login">Re-Enter your password:</label>

              <div style={{ position: "relative" }}>
                <input
                  className="input-login"
                  fullWidth
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  value={values.confirmPassword || ""}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="input-error">{errors.confirmPassword}</div>
                )}
                <button
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  className="show-password-button-signup"
                >
                  {showConfirmPassword ? (
                    <Tooltip title="Hide password">
                      <img
                        src={eyeopen}
                        alt="Hide Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show password">
                      <img
                        src={eyeoff}
                        alt="Show Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  )}{" "}
                </button>{" "}
              </div>
            </div>

            <div className="btn-login-submit btn-signup-submit">
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="common-button"
              >
                {loading ? "" : "Set Password"}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
