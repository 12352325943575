import React from "react";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Height } from "@mui/icons-material";
import aiRobo from "../../svg/ai-robo.png";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./mainpopup.css";
import chromelogo from "../../svg/chrome-logo.svg";
import { Link } from "react-router-dom";

const MainPopUp = (props) => {
  const { closeForm } = props;

  return (
    <>
      {closeForm ? (
        <IconButton
          aria-label="close"
          onClick={closeForm}
          className="close-icn"
          sx={{
            position: "absolute",
            right: 15,
            top: 2,
            color: "white",
          }}
        >
          <CloseIcon sx={{ height: "35px", width: "35px" }} />
        </IconButton>
      ) : null}

      <Grid className="container-mainpopup">
        <Grid>
          <img
            src={aiRobo}
            alt="AI ROBOT IMAGE"
            style={{ width: "375px", height: "475px" }}
          />
        </Grid>
        <Grid className="mainpopup-right">
          <Typography
            sx={{
              fontSize: "52px",
              fontStyle: "normal",
              fontWeight: "900",
              lineHeight: "normal",
              letterSpacing: "1.12px",
              mb: 2,
            }}
          >
            Add to Chrome
          </Typography>
          <Typography
            sx={{
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "normal",
              mb: 3,
            }}
          >
            Get your EXCLUSIVE trial free!
          </Typography>
          <Typography
            sx={{
              fontStyle: "normal",
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "normal",
              mb: 5,
            }}
          >
            Supercharge Your Upwork Journey: Free Yourself from Repetitive Tasks
            – Let Assistree Be Your Guide!
          </Typography>
          <a
            href="https://chromewebstore.google.com/detail/assistree/kbkcpibmagpfbkjhloipdkihakcpkall"
            target="_blank"
          >
            <button className="btn-mainpop" sx={{ mb: 3 }}>
              <img
                src={chromelogo}
                alt="ChromeLogo"
                style={{ height: "44px", width: "44px", margin: 0 }}
              />
              <span style={{ fontSize: "20px", fontWeight: "400" }}>
                GET ASSISTREE EXTENSION
              </span>
            </button>
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default MainPopUp;
