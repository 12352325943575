import React, { useEffect, useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik"; // Import Formik components
import userService from "../../service/service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { LoadingButton } from "@mui/lab";
import eyeoff from "../../svg/eye-svgrepo-com.svg";
import eyeopen from "../../svg/eye-off-svgrepo-com.svg";
import { Tooltip } from "@mui/material";
import GoogleWithSignUp from "../signUp/googleWithSignUp";
import loginLogo from "../../svg/LOGO-LOGIN.svg";
import vectorTop from "../../svg/Vector-top.png";
import vectorBottom from "../../svg/vector-bottom.png";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const loginElement = document.getElementById("login");
    if (loginElement) {
      loginElement.scrollTop = 0;
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid!")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password must not be empty"),
  });

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const registerUser = await userService.login({
        email: values?.email || "",
        password: values.password || "",
      });

      if (registerUser && registerUser.status === 200) {
        notifySuccess("Login successful");
        setLoading(false);
        const apiToken = registerUser.tokenLogin;
        localStorage.setItem("apiToken", apiToken);
        localStorage.setItem("email", values.email);

        navigate("/userDashboard");
      } else if (registerUser.status === 401) {
        setLoading(false);
        notifyError(registerUser.message);
      } else if (registerUser.status === 403) {
        setLoading(false);
        notifyError(registerUser.message);
      } else if (registerUser.status === 400) {
        setLoading(false);
        notifyError(registerUser.message);
      } else {
        setLoading(false);
        notifyError(registerUser.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="login" className="container">
      <div className="login-main-container">
        <div
          className="login-logo login-left"
          style={{ position: "relative", height: "569px" }}
        >
          <img src={vectorTop} className="vector-top" />
          <img
            src={loginLogo}
            alt="AssisTree"
            style={{ height: "15rem", marginLeft: "150px" }}
          />
          <img src={vectorBottom} className="vector-bottom" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          <div className="login-container" style={{ marginRight: "60px" }}>
            <Form>
              {" "}
              <h2>Login</h2>
              <div className="form-group">
                <label className="label-login" htmlFor="email">
                  Email
                </label>
                <Field
                  className="input-login"
                  autoComplete="off"
                  name="email"
                  type="email"
                  placeholder="Ex. xyz@example.com"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="input-error"
                />
              </div>
              <div className="form-group">
                <label className="label-login" htmlFor="password">
                  Password
                </label>
                <div style={{ position: "relative" }}>
                  <Field
                    className="input-login input-login-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="show-password-button"
                  >
                    {showPassword ? (
                      <Tooltip title="Hide password">
                        <img
                          src={eyeopen}
                          alt="Hide Password"
                          style={{ height: "25px" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show password">
                        <img
                          src={eyeoff}
                          alt="Show Password"
                          style={{ height: "25px" }}
                        />
                      </Tooltip>
                    )}
                  </button>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "3px",
                    }}
                  >
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="input-error"
                    />
                    <div style={{ textAlign: "right" }}>
                      <Link to="/forget_password">
                        <span className="forget-pass"> Forget password?</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-login-submit">
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="common-button"
                >
                  {loading ? "" : "Log In"}
                </LoadingButton>

                <p className="or-p" style={{ display: "none" }}>
                  Or
                </p>

                <GoogleWithSignUp
                  successMessage="User Sign in successfully"
                  failMessage="User have not sign in"
                />
              </div>
              <div className="link-signup">
                <h3 className="h3-login">Don't have an account?</h3>
                <Link to="/sign-up">
                  <h3 className="btn-signup h3-login">Sign Up!</h3>
                </Link>
              </div>
            </Form>
          </div>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
