import React, { useState } from "react";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import userService from "../../service/service";
import "./ForgetPassword.css";
import { LoadingButton } from "@mui/lab";
import loginLogo from "../../svg/LOGO-LOGIN.svg";
import vectorTop from "../../svg/Vector-top.png";
import vectorBottom from "../../svg/vector-bottom.png";

const ForgetPassWord = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is required! ")
      .max(255)
      .required("Email must be required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          const obj = {
            email: values?.email || "",
          };

          const registerUser = await userService.forgetPassword(obj);

          if (registerUser && registerUser.status === 200) {
            localStorage.setItem("email", values.email);

            navigate("/forget_password/verification");
            setLoading(false);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
    });
  return (
    <div className="container">
      <div className="login-main-container">
        <div
          className="login-logo login-left"
          style={{ position: "relative", height: "569px" }}
        >
          <img src={vectorTop} className="vector-top" />
          <img
            src={loginLogo}
            alt="AssisTree"
            style={{ height: "15rem", marginLeft: "150px" }}
          />
          <img src={vectorBottom} className="vector-bottom" />
        </div>
        <div id="top" className="login-container">
          <form onSubmit={handleSubmit} className="login-form">
            <h2>Forgot Password</h2>
            <div className="form-group">
              <label className="label-login">Email</label>
              <input
                className="input-login"
                fullWidth
                autoComplete="off"
                name="email"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ex. xyz@example.com"
                value={values.email || ""}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {touched.email && errors.email && (
                <div className="input-error">{errors.email}</div>
              )}
            </div>
            <div className="btn-login-submit btn-code">
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="common-button"
              >
                {loading ? "" : "Get Verification Code"}
              </LoadingButton>
            </div>
            <p className="or">Or</p>
            <div className="link-signup">
              <h3 className="h3-forget">Don't have an account?</h3>
              <Link to="/sign-up">
                <h3 className="btn-signup h3-forget">Create Account</h3>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassWord;
