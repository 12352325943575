import React from "react";
import "./CromeExtension.css";
import Chrome from "../svg/chrome-logo.svg";

import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
function CromeExtension() {
  const navigate = useNavigate();

  const downloadhandler = () => {
    navigate("/#BrowsePlan");
  };

  return (
    <div id="chromeExtension">
      <div
        className="text-center CromeExtension-main"
        style={{ paddingTop: "60px" }}
      >
        <div className="container">
          <div className="section-title container-title">
            <div className="CromeExtension-container-middle">
              <h2>Get Your Complimentary Trial Now - Simply Sign Up!</h2>
              <h3>
                Supercharge Your Upwork Journey: Free Yourself from Repetitive
                Tasks – Let Assistree Be Your Guide!
              </h3>

              <div className="Download-extension-btn">
                <LoadingButton
                  href="#BrowsePlan"
                  type="button"
                  loadingPosition="start"
                  onClick={downloadhandler}
                  variant="contained"
                  fullWidth
                  className="common-button DownloadButton"
                >
                  Get Started For Free{" "}
                </LoadingButton>

                <LoadingButton
                  type="button"
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="common-button DownloadButton"
                >
                  <Link
                    to="https://chromewebstore.google.com/detail/assistree/kbkcpibmagpfbkjhloipdkihakcpkall"
                    target="_blank"
                    className="ChromeStoreLink"
                  >
                    <img className="btn-Chrome-img" src={Chrome} />
                    Add Assistree To Chrome
                  </Link>
                </LoadingButton>
              </div>
              <div className="footer-download-section">
                <img src={Chrome} alt="Chrome" style={{ height: "35px" }} />
                <div className="rating-d">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      className={`star-d ${5 <= 5 ? "filled" : ""}`}
                      style={{ fontSize: "30px" }}
                    ></span>
                  ))}
                </div>
                <span>Rated 4.8/5 on Chrome Store</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CromeExtension;
