import React, { useState } from "react";
import { useFormik } from "formik";
import userService from "../../service/service";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "../login/login.css";
import "./signup.css";
import { notifyError, notifySuccess } from "../../utils/common.service";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import eyeoff from "../../svg/eye-svgrepo-com.svg";
import eyeopen from "../../svg/eye-off-svgrepo-com.svg";
import loginLogo from "../../svg/LOGO-LOGIN.svg";
import GoogleWithSignUp from "./googleWithSignUp";
import vectorTop from "../../svg/Vector-top.png";
import vectorBottom from "../../svg/vector-bottom.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Email is required!")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password must be not empty"),
    confirmPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password must be not empty"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          setLoading(true);
          if (values.password !== values.confirmPassword) {
            notifyError("Password Does Not Match");
            setLoading(false);
          } else {
            const obj = {
              username: values?.username || "",
              email: values?.email || "",
              password: values.password || "",
              confirmPassword: values?.confirmPassword || "",
            };

            const registerUser = await userService.register(obj);

            if (registerUser && registerUser.status === 200) {
              localStorage.setItem("email", values.email);
              navigate("/user_verification");
              setLoading(false);
              notifySuccess(
                "User saved successfully, please verify Your Email"
              );
            }
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container">
      <div className="login-main-container">
        <div
          className="login-logo login-left"
          style={{ position: "relative", height: "701px" }}
        >
          <img src={vectorTop} className="vector-top" />
          <img
            src={loginLogo}
            alt="AssisTree"
            style={{ height: "15rem", marginLeft: "150px" }}
          />
          <img src={vectorBottom} className="vector-bottom" />
        </div>
        <div id="top" className="login-container">
          <form className="login-form" noValidate onSubmit={handleSubmit}>
            <h2>Sign-Up</h2>
            <div className="form-group">
              <label className="label-login">UserName:</label>
              <input
                className="input-login"
                fullWidth
                autoComplete="off"
                name="username"
                type="text"
                label="User Name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ex. johns , Alberts"
                value={values.username || ""}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
              {touched.username && errors.username && (
                <div className="input-error">{errors.username}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-login">Email:</label>
              <input
                className="input-login"
                fullWidth
                autoComplete="off"
                name="email"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ex. xyz@example.com"
                value={values.email || ""}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {touched.email && errors.email && (
                <div className="input-error">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-login">Password:</label>
              <div style={{ position: "relative" }}>
                <input
                  className="input-login"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <button
                  type="button"
                  onClick={toggleShowPassword}
                  className="show-password-button-signup"
                >
                  {showPassword ? (
                    <Tooltip title="Hide password">
                      <img
                        src={eyeopen}
                        alt="Hide Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show password">
                      <img
                        src={eyeoff}
                        alt="Show Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  )}{" "}
                </button>{" "}
              </div>

              {touched.password && errors.password && (
                <div className="input-error">{errors.password}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-login">Re-Enter your password:</label>

              <div style={{ position: "relative" }}>
                <input
                  className="input-login"
                  fullWidth
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  value={values.confirmPassword || ""}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="input-error">{errors.confirmPassword}</div>
                )}
                <button
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  className="show-password-button-signup"
                >
                  {showConfirmPassword ? (
                    <Tooltip title="Hide password">
                      <img
                        src={eyeopen}
                        alt="Hide Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show password">
                      <img
                        src={eyeoff}
                        alt="Show Password"
                        style={{ height: "25px" }}
                      />
                    </Tooltip>
                  )}{" "}
                </button>{" "}
              </div>
            </div>

            <div className="btn-login-submit btn-signup-submit">
              <div className="btn-login-submit">
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="common-button"
                >
                  {loading ? "" : "Sign Up"}
                </LoadingButton>

                <p className="or-p" style={{ display: "none" }}>
                  Or
                </p>

                <GoogleWithSignUp
                  successMessage="User Sign in successfully"
                  failMessage="User have not sign in"
                />
              </div>

              <div className="link-signup" style={{ margin: "0" }}>
                <h3 className="h3-signup">Do you already have an account?</h3>
                <Link to="/login">
                  <h3 className="btn-signup h3-signup">Log In</h3>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
