import React from "react";
import email from "../../svg/email.svg";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="login-main-container" style={{ display: "block" }}>
        <div>
          <h1 style={{ color: "#333" }}>Privacy Policy</h1>
          <p style={{ color: "#777", padding: "5px 30px" }}>
            At Asistree, we value your privacy and are committed to protecting
            your personal information. This Privacy Policy outlines how we
            collect, use, disclose, and manage your data when you use our
            services, including but not limited to login, signup, account
            management, transactions, and credit usage. By using our services,
            you consent to the practices described in this Privacy Policy.
          </p>
        </div>
        <div>
          <h1 style={{ color: "#333", fontSize: "30px" }}>
            Information We Collect
          </h1>
          <div style={{ padding: "5px 30px" }}>
            <h1 style={{ fontSize: "25px" }}>Personal Information:</h1>
            <p style={{ color: "#777" }}>
              When you create an account or use our services, we may collect
              personal information, including but not limited to your name,
              email address, and payment information.
            </p>
            <h1 style={{ fontSize: "25px" }}>Transaction Data:</h1>
            <p style={{ color: "#777" }}>
              We collect data related to your transactions, including payment
              history, subscription details, and transaction IDs.
            </p>
            <h1 style={{ fontSize: "25px" }}>Credit Usage:</h1>
            <p style={{ color: "#777" }}>
              Information regarding your credit usage and balance is stored
              securely for billing and service provision purposes.
            </p>
            <h1 style={{ fontSize: "25px" }}>Device and Usage Information:</h1>
            <p style={{ color: "#777" }}>
              We may collect information about your device, browser, and usage
              patterns, such as IP address, browser type, pages visited, and
              actions taken on our website.
            </p>
          </div>

          <h1 style={{ color: "#333", fontSize: "30px" }}>
            How We Use Your Information
          </h1>
          <div style={{ padding: "5px 30px" }}>
            <h1 style={{ fontSize: "25px" }}>Service Provision:</h1>
            <p style={{ color: "#777" }}>
              We use your personal information to provide you with access to our
              services, manage your account, and process transactions.
            </p>
            <h1 style={{ fontSize: "25px" }}>Communication:</h1>
            <p style={{ color: "#777" }}>
              We may use your contact information to communicate with you about
              account-related matters, service updates, and promotional offers.
            </p>
            <h1 style={{ fontSize: "25px" }}>
              Data Usage Limited to Authentication:
            </h1>
            <p style={{ color: "#777" }}>
              Clearly state in your privacy policy that the data collected
              <b> username</b> and <b>email</b> through Google authentication
              will only be used for the purpose of user authentication and
              account management within your system. Ensure that this data will
              not be used for any other purposes, such as marketing, without the
              user's explicit consent.
            </p>
            <h1 style={{ fontSize: "25px" }}>Billing:</h1>
            <p style={{ color: "#777" }}>
              Your payment information is used to process payments for our
              services and to maintain accurate billing records.
            </p>
            <h1 style={{ fontSize: "25px" }}>Analytics:</h1>
            <p style={{ color: "#777" }}>
              We analyze user data to improve our services, enhance user
              experience, and optimize our website.
            </p>
            <h1 style={{ fontSize: "25px" }}>Data Security</h1>
            <p style={{ color: "#777" }}>
              We employ industry-standard security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction. Our payment processing partner,
              Stripe, complies with Payment Card Industry Data Security Standard
              (PCI DSS) requirements.
            </p>
            <h1 style={{ fontSize: "25px" }}>Data Sharing</h1>
            <p style={{ color: "#777" }}>
              We do not sell, trade, or rent your personal information to third
              parties. However, we may share your data with trusted partners and
              service providers who assist us in delivering our services,
              provided they agree to protect your data in accordance with this
              Privacy Policy.
            </p>
            <h1 style={{ fontSize: "25px" }}>
              Cookies and Tracking Technologies
            </h1>
            <p style={{ color: "#777" }}>
              We use cookies and similar tracking technologies to enhance your
              browsing experience, analyze website usage, and personalize
              content. You can manage your cookie preferences through your
              browser settings.
            </p>
            <h1 style={{ fontSize: "25px" }}>Data Retention</h1>
            <p style={{ color: "#777" }}>
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy or as
              required by applicable laws. After termination of your account, we
              will securely delete or anonymize your data as appropriate.
            </p>
            <h1 style={{ fontSize: "25px" }}>Your Rights</h1>
            <p style={{ color: "#777" }}>
              You have the right to access, correct, or delete your personal
              information. To exercise these rights or request more information
              about your data, please contact us.
            </p>

            <h1 style={{ fontSize: "25px" }}>Changes to Privacy Policy</h1>
            <p style={{ color: "#777" }}>
              We may update this Privacy Policy as our services evolve or to
              comply with legal requirements. We will post any changes on our
              website, and your continued use of our services after such changes
              constitute acceptance of the updated policy.
            </p>

            <h1 style={{ fontSize: "25px" }}>Contact Us</h1>
            <p style={{ color: "#777" }}>
              If you have questions or concerns about our Privacy Policy, please
              contact us at
              <img src={email} alt="Email:" style={{ height: "15px" }} />
              <span>info@assistree.com.</span>
            </p>
            <p style={{ color: "#777" }}>
              By using our services, you acknowledge that you have read,
              understood, and agree to the practices described in this Privacy
              Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
