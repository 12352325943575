import React, { useState } from "react";
import "../UserDashboard/UserDashboard.css";
import "../login/login.css";
import { useFormik } from "formik";

import { loadStripe } from "@stripe/stripe-js";

import PaymentOut from "./PaymentOut";

const stripePromise = loadStripe(
  "pk_test_51Nl6qfSBdjgkY7vCqwJHeJyCOflWrvFBqRikZC8r6yyeGXOXnARuOCvtSycprLkddEd10RQo2NExOlspWiFfjvLH00i3er6Tfg"
);

const CreatePayment = () => {
  const options = {
    clientSecret:
      "sk_test_51Nl6qfSBdjgkY7vC6Vy6hDi4xA9zsGa6vfoy2LsBCcrfeBMRh7fnm3FKRUsGMOl3NbYE1Wm4K8wRhVkuU5HceSA800UktEMA8Z",
  };

  return (
    <div id="plans" className="plans-homepage">
      <PaymentOut />
    </div>
  );
};

export default CreatePayment;
