import React, { useState } from "react";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import userService from "../../service/service";
import "../ForgetPassword/ForgetPassword.css";
import "./verification.css";
import OTPInput from "react-otp-input";
import { LoadingButton } from "@mui/lab";
import { notifyError, notifySuccess } from "../../utils/common.service";

const ForgetPasswordVerification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const initialValues = {
    otp: "",
    submit: null,
  };

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      onSubmit: async () => {
        try {
          const obj = {
            otp: otp.toString() || "",
          };
          const registerUser = await userService.isValidOtp(obj);

          if (registerUser && registerUser.status === 200) {
            notifySuccess("OTP Verify Successfully, Set Your New Password!");
            navigate("/setPassword");
            setLoading(false);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          notifyError("OTP Invalid!");
        }
      },
    });

  function resendCodeHandler() {
    // const obj = {
    //   email: email,
    // };
    // userService.resendCode(obj).then((res) => {
    //   if (res && res.status === 200) {
    //     notifySuccess("Verification mail sent successfully");
    //     setLoading(false);
    //   } else {
    //     setLoading(false);
    //     notifyError("Error Resend Code");
    //   }
    // });
  }

  return (
    <div>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form form-verify">
          <h2>Verify Your Account</h2>
          <div className="form-group">
            <label className="label-login form-label">
              Enter Code Which you Get in Your E-mail
            </label>
            <div className="input-verify">
              <OTPInput
                name="otp"
                onBlur={handleBlur}
                value={otp || ""}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              {touched.otp && errors.otp && (
                <div className="input-error">{errors.otp}</div>
              )}
            </div>
          </div>

          <div className="btn-login-submit btn-code">
            <LoadingButton
              type="submit"
              loading={loading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="common-button"
            >
              {loading ? "" : "Verify"}
            </LoadingButton>
          </div>
          <div className="link-signup">
            <h3 className="h3-forget">Don't get an Code?</h3>
            <h3 className="btn-signup h3-forget" onClick={resendCodeHandler}>
              Resend Code
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordVerification;
