import React from "react";

import userIcon from "../svg/user-icon.svg";

const Testimonials = (props) => {
  return (
    <div id="testimonials" style={{ paddingTop: "110px" }}>
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
          <div className="group-review">
            {props.data
              ? props.data.map((d, i) => (
                  <>
                    <div className="user-review-card">
                      <div className="review-card-top">
                        <div>
                          <img
                            src={userIcon}
                            alt="user-icon"
                            style={{ height: "60px", marginBottom: "6px" }}
                          />{" "}
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p className="reviewer-name">{d.name}</p>
                          <p>{d.role}</p>
                        </div>
                      </div>
                      <div className="review-card-data">
                        <div className="rating-d">
                          {[1, 2, 3, 4, 5].map((value) => (
                            <span
                              key={value}
                              className={`star-d ${5 <= 5 ? "filled" : ""}`}
                              style={{ fontSize: "30px" }}
                            ></span>
                          ))}
                        </div>
                        <p className="reviewer-description">{d.text}</p>
                      </div>
                    </div>
                  </>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
