import React from "react";
import "./BrowsePlans.css";
import CreatePayment from "./Payment/CreatePayment";
import { useNavigate } from "react-router-dom";

const Team = (props) => {
  const navigata = useNavigate();

  const createPaymentHandler = () => {
    const token = localStorage.getItem("apiToken");
    if (token) {
      navigata("/userDashboard#plans");
    } else {
      navigata("/login");
      window.scrollTo(0, 0);
    }
  };

  const token = localStorage.getItem("apiToken");

  return (
    <div
      id="BrowsePlan"
      className="text-center"
      style={{ paddingTop: "110px" }}
    >
      <div className="container">
        <div className="text-center section-title">
          <h2>🚀 Choose Your Ideal Plan</h2>
          <p
            className="p-text"
            style={{ textAlign: "center", marginBottom: "10px" }}
          >
            Explore All of Assistree's Features with 5 Free credit – No Extra
            Cost Required!
          </p>
        </div>
        <div style={{ marginBottom: "30px" }}>
          <div
            className={!token ? "transparent-div" : ""}
            onClick={createPaymentHandler}
          >
            <CreatePayment />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
