import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Card from "@mui/material/Card";
import "./CustomizationModal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    overflowY: "hidden",
    minWidth: "60%",
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    border: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
    border: 0,
  },
}));

export default function CustomizationDialog(props) {
  let { open, handleClose, title, children } = props;

  return (
    <>
      <Card
        sx={{
          display: open ? "block" : "none",
        }}
      >
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{ overflow: "auto", overflowY: "hidden !important" }}
          className="main-popup"
        >
          <div className="bootstrap-dialog-title">
            <DialogContent
              dividers
              sx={{ overflow: "auto", maxHeight: "675px" }}
            >
              {children}
            </DialogContent>
          </div>
        </BootstrapDialog>
      </Card>
    </>
  );
}
