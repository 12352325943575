import React from "react";
import { SERVICES_DATA } from "../utils/constant";

const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Assistree: The Ideal Solution for Whom?</h2>
          <p>
            Whether you're a seasoned freelancer or just stepping into the
            Upwork arena, Assistree is your game-changer. With proposal
            submissions and project hunting as your daily grind, Assistree is
            your ticket to a streamlined workflow. No matter where you are in
            your freelancing journey, our tool is your key to efficiency. If
            you're ready to simplify your Upwork experience and reclaim valuable
            hours, Secure Your Free Trial Today!"
          </p>
        </div>

        <div className="row">
          {SERVICES_DATA.Services.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              {" "}
              <img src={d.svg}></img>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
