import { Outlet } from "react-router-dom";
import { useEffect } from "react";

import Navigation from "../navigation";
import Contact from "../contact";
import { useState } from "react";
import JsonData from "../../data/data.json";

function RootLayout() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <Navigation />
      <main>
        <Outlet />
      </main>
      <Contact data={landingPageData.Contact} />
    </>
  );
}
export default RootLayout;
